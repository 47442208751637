/*!

=========================================================
* BLK Design System PRO React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

export default function Info() {
  return (
    <>
      <Container>
        <Row>
          <Col md="4">
            <div className="info info-hover">
              <div className="icon icon-primary">
                <img
                  alt="..."
                  className="bg-blob"
                  src={require("assets/img/feature-blob/primary.png")}
                />
                <i className="tim-icons icon-user-run" />
              </div>
              <h2 className="info-title">Progress Tracker</h2>
              <h4 className="description">
              Keep tabs on your goals with live updates and tips, keeping you motivated and moving forward on your success journey.
              </h4>
            </div>
          </Col>
          <Col md="4">
            <div className="info info-hover" style={{ marginTop: "-50px" }}>
              <div className="icon icon-warning">
                <img
                  alt="..."
                  className="bg-blob"
                  src={require("assets/img/feature-blob/warning.png")}
                />
                <i className="tim-icons icon-atom" />
              </div>
              <h2 className="info-title">Journal Hub</h2>
              <h4 className="description">
                Write down your wins and moments with pics, crafting a story of your progress and adventures along the way.
              </h4>
            </div>
          </Col>
          <Col md="4">
            <div className="info info-hover">
              <div className="icon icon-success">
                <img
                  alt="..."
                  className="bg-blob"
                  src={require("assets/img/feature-blob/success.png")}
                />
                <i className="tim-icons icon-gift-2" />
              </div>
              <h2 className="info-title">Achievement Corner</h2>
              <p className="description">
                Flaunt your wins, and join the party! Share your successes, cheer on others, and soak up the inspiration.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
